import { combineReducers } from "redux";
import vehiclesReducer from "./vehicleReducer";
import labeledVehiclesReducer from "./labeledVehiclesReducer";
import settingsReducer from "./settingsReducer";

const rootReducer = combineReducers({
  vehicle: vehiclesReducer,
  labeledVehicles: labeledVehiclesReducer,
  settings: settingsReducer,
});

export default rootReducer;

//export type State = ReturnType<typeof rootReducer>;
