import { ReactElement, ReactNode } from "react";
export const VEHICLES_LOADING = "VEHICLES_LOADING";
export const VEHICLES_FAILURE = "VEHICLES_FAILURE";
export const VEHICLES_SUCCESS = "VEHICLES_SUCCESS";

export type VehicleType = {
  vehicles: Array<Vehicles>;
};

export type Vehicles = {
  VIN: string;
  DCM: string;
  AssetNumber: string;
  IdleHrs: number;
  ParkHrs: number;
  EngHrs: number;
  Status: string;
};

export interface VehicleLoading {
  type: typeof VEHICLES_LOADING;
}

export interface VehicleFailure {
  type: typeof VEHICLES_FAILURE;
}

export interface VehicleSuccess {
  type: typeof VEHICLES_SUCCESS;
  payload: Array<Vehicles>;
}

export type VehicleDispatchTypes =
  | VehicleLoading
  | VehicleFailure
  | VehicleSuccess;
